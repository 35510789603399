import React from 'react';
import { Image, LinkBase, Text } from '@belong/ui';
import clsx from 'clsx';
import styles from './market-banner.module.css';

const stateConfig = {
  title: 'Pennsylvania',
  backgroundColor: '#0D3D5A',
  // TODO: Replace blog post with Pennsylvania one
  blogPost: '/blog/belong-arrives-in-nevada-jackpot-for-homeowners-and-renters',
  imagesPrefix: 'banners/lp_banner_pa',
};

const imagesConfig = {
  leftDesktopImage: `${stateConfig.imagesPrefix}/lp_pa_banner_left.svg`,
  leftMobileImage: `${stateConfig.imagesPrefix}/lp_pa_banner_left_m.svg`,
  rightDesktopImage: `${stateConfig.imagesPrefix}/lp_pa_banner_right.svg`,
  rightMobileImage: `${stateConfig.imagesPrefix}/lp_pa_banner_right_m.svg`,
};

export function MarketBanner() {
  return (
    <div
      className={clsx('relative flex justify-between z-50 w-full', styles.banner)}
      style={{ backgroundColor: stateConfig.backgroundColor }}
    >
      <div className="flex md:flex-1 md:justify-end overflow-hidden md:mr-2xl absolute md:relative left-0 bottom-0 h-full">
        <Image
          className="max-w-none w-auto md:hidden h-full"
          src={imagesConfig.leftMobileImage}
          alt=""
          aria-hidden="true"
        />
        <Image
          className="hidden md:block w-auto max-w-none"
          src={imagesConfig.leftDesktopImage}
          alt=""
          aria-hidden="true"
        />
      </div>
      <LinkBase
        href={stateConfig.blogPost}
        className="flex flex-col flex-3 md:flex-1 md:flex-row items-center justify-center text-center leading-p1 gap-y-2xs md:whitespace-nowrap z-10"
      >
        <Text className="text-white leading-p1 px-2xl md:px-0">
          We&apos;ve added <strong>{stateConfig.title}</strong> to&nbsp;Belong!{' '}
        </Text>
        <Text
          fontWeight="semibold"
          className="text-green leading-p1 pl-xs hover:text-light-green z-20"
          style={{ textShadow: '2px 0px 10px #3EE492CC;', transition: 'all 0.1s ease-in-out' }}
        >
          LEARN MORE
        </Text>
      </LinkBase>
      <div className="flex md:flex-1 items-end md:justify-start overflow-hidden md:ml-2xl absolute md:relative right-0 bottom-0 h-full">
        <Image
          className="max-w-none w-auto md:hidden h-full"
          src={imagesConfig.rightMobileImage}
          alt=""
          aria-hidden="true"
        />
        <Image
          className="hidden md:block w-auto max-w-none"
          src={imagesConfig.rightDesktopImage}
          alt=""
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
